<template>
  <SasModal class="welcome-modal">
    <div
      slot="body"
      class="welcome-modal__body"
    >
      <div class="welcome-modal__illustration">
        <img
          alt="welcome illustration"
          :src="imageSource"
        >
      </div>

      <Carousel
        class="welcome-modal__carousel"
        :per-page="1"
        :value="slideIndex"
        @pageChange="changeSlideIndex()"
      >
        <Slide
          v-for="slide in slides"
          :key="slide.index"
        >
          <div class="welcome-modal__info">
            <h3 class="info__title">
              {{ slide.info.title }}
            </h3>

            <p class="info__description">
              {{ slide.info.description }}
            </p>
          </div>
        </Slide>
      </Carousel>
    </div>

    <div
      slot="footer"
      class="welcome-modal__actions"
    >
      <div class="actions__left">
        <SasButton
          size="big"
          theme="float"
          @click="$emit('close')"
        >
          Agora não
        </SasButton>
      </div>

      <div class="actions__right">
        <SasButton
          v-if="slideIndex && ($mq === 'x_large' || $mq === 'large')"
          size="big"
          theme="secondary"
          @click="back()"
        >
          <Icon
            slot="left"
            type="arrow-left"
          />
          Anterior
        </SasButton>

        <SasButton
          v-if="slideIndex < slides.length - 1"
          size="big"
          @click="next()"
        >
          Próximo
          <Icon
            v-if="$mq === 'x_large' || $mq === 'large'"
            slot="right"
            stroke="white"
            type="arrow-right"
          />
        </SasButton>

        <SasButton
          v-else
          size="big"
          @click="startTour()"
        >
          Começar {{ $mq === 'x_large' || $mq === 'large' ? 'o tour!' : '' }}
        </SasButton>
      </div>
    </div>
  </SasModal>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

import image1 from '@/assets/welcome-illustration-1.svg'
import image2 from '@/assets/welcome-illustration-2.svg'
import image3 from '@/assets/welcome-illustration-3.svg'
import image4 from '@/assets/welcome-illustration-4.svg'
import image5 from '@/assets/welcome-illustration-5.svg'

export default {
  name: 'WelcomeModal',

  components: {
    Carousel,
    Slide,
  },

  data() {
    return {
      slideIndex: 0,
      slides: [
        {
          index: 0,
          image: image1,
          info: {
            title: 'Bem-vindo(a) às Avaliações Digitais',
            description: `Agora, além de usar o meio digital para fazer tarefas, assistir videoaulas e acessar
              outros conteúdos do SAS, você também pode fazer Avaliações.`,
          },
        },
        {
          index: 1,
          image: image2,
          info: {
            title: 'Faça provas usando o que estiver à mão',
            description: `Seja no computador, no tablet ou no celular, basta estar conectado à internet para
              conseguir fazer as provas. `,
          },
        },
        {
          index: 2,
          image: image3,
          info: {
            title: 'Você piscou e a nota saiu',
            description: `Sua nota é calculada imediatamente após finalizar a prova.
              Você não precisa mais ficar naquela ansiedade esperando a correção.`,
          },
        },
        {
          index: 3,
          image: image4,
          info: {
            title: 'Fazemos um raio-x do seu desempenho',
            description: `Usando visão de raio-x, fazemos uma análise detalhada do seu desempenho para
              apontar pontos fortes e de melhoria.`,
          },
        },
        {
          index: 4,
          image: image5,
          info: {
            title: 'Que tal fazer um tour?',
            description: 'Convidamos você para um passeio pelos principais recursos das avaliações digitais.',
          },
        },
      ],
    }
  },

  computed: {
    imageSource() {
      return this.slides[this.slideIndex].image
    },
  },

  methods: {
    back() {
      this.slideIndex -= 1
    },

    next() {
      this.slideIndex += 1
    },

    changeSlideIndex(index) {
      this.slideIndex = index
    },

    startTour() {
      this.$emit('start')
    },
  },
}
</script>

<style lang="scss">
 @mixin mq_modal_landscape() {
  @media (orientation: landscape) and (max-width: $device_xs),
    (orientation: landscape) and (max-height: $device_s) {
      @content;
    }
 }

.welcome-modal {

  .modal {
    border-radius: 16px;
    padding: 0 0 24px 0;

    @include mq_modal_landscape {
      padding: 0;
      border-radius: 0;
    }
  }

  .modal__close {
    display: none;
  }

  .modal__body {
    height: 100%;
  }

  .modal__footer {

    @include mq-l {
      background: white;
    }

    @include mq_modal_landscape {
      left: unset;
      width: 65%;
      right: 0;
      box-shadow: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 856px;
    text-align: center;

    @include mq-xs {
      min-height: 496px
    }

    @include mq_modal_landscape {
      flex-direction: row;
      text-align: left;
    }
  }

  &__illustration {
    display: flex;
    height: 344px;
    background-color: #0954AD;

    @include mq-s {
      height: 220px;
    }

    @include mq_modal_landscape {
      width: 35%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__carousel {
    @include mq_modal_landscape {
      width: 65%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 42px 24px 0 24px;

    @include mq-xs {
      padding: 16px;
    }

    @include mq_modal_landscape {
      padding: 24px;
      width: 100%;
      align-items: start;
    }

    .info__title {
      margin-bottom: 16px;

      @include mq-s {
        font-size: 20px;
        width: 232px;
      }
    }

    .info__description {
      max-width: 560px;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    padding: 0 32px;

    @include mq-m {
      justify-content: space-between;
      padding: 0 8px;
    }

    @include mq_modal_landscape {
      justify-content: space-between;
      padding: 0 8px;
    }

    .actions__left {
      width: fit-content;
    }

    .actions__right {
      display: flex;
      justify-content: center;
      width: 70%;

      @include mq-m {
        justify-content: right;
        width: auto;
      }

      @include mq_modal_landscape {
        justify-content: right;
      }
    }
  }

  .VueCarousel-dot {
    margin-top: 0 !important;

    &--active {
      background-color: $color-primary !important;
    }

    &-container {
      @include mq-xs {
        margin-top: 0 !important;
      }

      @include mq_modal_landscape {
        margin-top: 0 !important
      }
    }

    &:focus {
      outline: unset;
    }
  }
}
</style>
